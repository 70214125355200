import firebase from 'firebase/compat/app';
import { getAnalytics } from 'firebase/analytics';
import 'firebase/compat/auth';

const config = {
  apiKey: 'AIzaSyBAMMhlqdJM2rFr5S5mkgAKHwYUTXhvNrM',
  authDomain: 'simasco-firebase.firebaseapp.com',
  projectId: 'simasco-firebase',
  storageBucket: 'simasco-firebase.appspot.com',
  messagingSenderId: '128128309313',
  appId: '1:128128309313:web:0c724c4540491251368ec0',
  measurementId: 'G-MZH8XBM20P'
};

const app = firebase.initializeApp(config);
getAnalytics(app);

export default firebase;
