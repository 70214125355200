import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './App.min.css';
// custom moduling
import LoadingSpinner from './components/LoadingSpinner';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.min.css';
import { ToastContainer } from 'react-toastify';
import { Provider, useSelector } from 'react-redux';
import DefaultLayout from './layout/DefaultLayout';
import { persistor, store } from './redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import Providers from './components/providers';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ScrollToTop from './lib/ScrollToTop';

const HomeView = lazy(() => import('./views/Home'));
const SignInEmailView = lazy(() => import('./views/account/SignInEmail'));
const SignInPhoneView = lazy(() => import('./views/account/SignInPhoneForm'));
const VerifyOtpPhone = lazy(() => import('./views/account/VerifyOtpPhone'));
const VerifyOtpEmail = lazy(() => import('./views/account/VerifyOtpEmail'));
const SignUpEmailView = lazy(() => import('./views/account/SignUpEmail'));
const ForgotPasswordEmailView = lazy(() => import('./views/account/ForgotPasswordEmail'));
const ResetPasswordEmailView = lazy(() => import('./views/account/ResetPasswordEmail'));
const OrdersView = lazy(() => import('./views/account/Orders'));
const WishlistView = lazy(() => import('./views/account/Wishlist'));
const NotificationView = lazy(() => import('./views/account/Notification'));
const NotificationAllView = lazy(() => import('./views/account/NotificationAll'));
const MyTransactionView = lazy(() => import('./views/account/MyTransaction'));
const TransactionDetailView = lazy(() => import('./views/account/TransactionDetail'));
const MyProfileView = lazy(() => import('./views/account/MyProfile'));
const MyListAddressView = lazy(() => import('./views/account/address/MyListAddress'));
const AccountAddAddressView = lazy(() => import('./views/account/address/AccountAddAddress'));
const AccountEditAddressView = lazy(() => import('./views/account/address/AccountEditAddress'));
const ProductListView = lazy(() => import('./views/product/List'));
const ProductCategoryListView = lazy(() => import('./views/product/ListCategory'));
const ProductDetailView = lazy(() => import('./views/product/Detail'));
const PromoView = lazy(() => import('./views/promo/Promo'));
const PromoDetailView = lazy(() => import('./views/promo/detail/PromoDetail'));
const StarZoneView = lazy(() => import('./views/product/StarZone'));
const CartView = lazy(() => import('./views/cart/Cart'));
const CheckoutView = lazy(() => import('./views/cart/Checkout'));
const InvoiceView = lazy(() => import('./views/cart/Invoice'));
const DocumentationView = lazy(() => import('./views/Documentation'));
const NotFoundView = lazy(() => import('./views/pages/404'));
const InternalServerErrorView = lazy(() => import('./views/pages/500'));
const ContactUsView = lazy(() => import('./views/pages/ContactUs'));
const SupportView = lazy(() => import('./views/pages/Support'));
const BlogView = lazy(() => import('./views/blog/Blog'));
const BlogDetailView = lazy(() => import('./views/blog/Detail'));
const ReturnPolicyView = lazy(() => import('./views/return-policy/ReturnPolicy'));
const TermsOfUseView = lazy(() => import('./views/terms-of-use/TermsOfUse'));
const PrivacyPolicyView = lazy(() => import('./views/privacy-policy/PrivacyPolicy'));

function App() {
  const loading = useSelector((state) => state.loading.loading);

  return (
    <BrowserRouter>
      <Providers>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <TawkMessengerReact propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID} widgetId={process.env.REACT_APP_TAWK_WIDGET_ID} />
        <React.Fragment>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <LoadingSpinner isLoading={loading} />
              <DefaultLayout>
                <Suspense fallback={<div className="text-white text-center mt-3">Loading...</div>}>
                  <ScrollToTop />
                  <Routes>
                    <Route exact path="/" element={<HomeView />} />
                    <Route exact path="/account/email/signin" element={<SignInEmailView />} />
                    <Route exact path="/account/phone/signin" element={<SignInPhoneView />} />
                    <Route exact path="/account/phone/verify-otp" element={<VerifyOtpPhone />} />
                    <Route exact path="/account/email/verify-otp" element={<VerifyOtpEmail />} />
                    <Route exact path="/account/email/signup" element={<SignUpEmailView />} />
                    <Route exact path="/account/email/forgotpassword" element={<ForgotPasswordEmailView />} />
                    <Route exact path="/account/email/resetpassword" element={<ResetPasswordEmailView />} />
                    <Route exact path="/account/transaction" element={<MyTransactionView />} />
                    <Route exact path="/account/transaction/detail/:id" element={<TransactionDetailView />} />
                    <Route exact path="/account/profile" element={<MyProfileView />} />
                    <Route exact path="/account/list-address" element={<MyListAddressView />} />
                    <Route exact path="/account/add-address" element={<AccountAddAddressView />} />
                    <Route exact path="/account/edit-address/:id" element={<AccountEditAddressView />} />
                    <Route exact path="/account/orders" element={<OrdersView />} />
                    <Route exact path="/account/wishlist" element={<WishlistView />} />
                    <Route exact path="/account/notification" element={<NotificationView />} />
                    <Route exact path="/account/notifications" element={<NotificationAllView />} />
                    <Route exact path="/category" element={<ProductListView />} />
                    <Route exact path="/category/:name" element={<ProductCategoryListView />} />
                    <Route exact path="/promo" element={<PromoView />} />
                    <Route exact path="/promo/detail/:id" element={<PromoDetailView />} />
                    <Route exact path="/product/detail/" element={<ProductDetailView />} />
                    <Route exact path="/product/detail/:id" element={<ProductDetailView />} />
                    <Route exact path="/star/zone" element={<StarZoneView />} />
                    <Route exact path="/cart" element={<CartView />} />
                    <Route exact path="/checkout" element={<CheckoutView />} />
                    <Route exact path="/invoice" element={<InvoiceView />} />
                    <Route exact path="/documentation" element={<DocumentationView />} />
                    <Route exact path="/contact-us" element={<ContactUsView />} />
                    <Route exact path="/support" element={<SupportView />} />
                    <Route exact path="/blog" element={<BlogView />} />
                    <Route exact path="/blog/detail" element={<BlogDetailView />} />
                    <Route exact path="/return-policy" element={<ReturnPolicyView />} />
                    <Route exact path="/terms-of-use" element={<TermsOfUseView />} />
                    <Route exact path="/privacy-policy" element={<PrivacyPolicyView />} />
                    <Route exact path="/500" element={<InternalServerErrorView />} />
                    <Route path="*" element={<NotFoundView />} />
                  </Routes>
                </Suspense>
              </DefaultLayout>
            </PersistGate>
          </Provider>
        </React.Fragment>
      </Providers>
    </BrowserRouter>
  );
}

export default App;
