/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let debounceTimer;

  useEffect(() => {
    const fetchProductList = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/client/product/data/search?name=${searchTerm}`);
        const data = await response.json();
        setProductList(data);
      } catch (error) {
        toast.error('Terjadi Kesalahan, Silahkan Coba Kembali Nanti!');
      } finally {
        setIsLoading(false);
      }
    };

    const debounceSearch = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        if (searchTerm.trim() !== '') {
          fetchProductList();
        } else {
          setProductList([]);
        }
      }, 300);
    };

    debounceSearch();

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const handleProductChange = (selectedOption) => {
    if (selectedOption) {
      navigate(`/product/detail/${selectedOption.value}`);
      window.location.reload();
    }

    setSearchTerm('');
  };

  return (
    <form action="#" className="search">
      <Select
        options={productList.map((product) => ({ value: product.id, label: `${product.name} - ${product.company_name}` }))}
        isSearchable
        placeholder="Search"
        onInputChange={(value) => setSearchTerm(value ? value : '')}
        onChange={handleProductChange}
        isLoading={isLoading}
        value={null}
      />
    </form>
  );
};

export default Search;

// import React from 'react';
// import { ReactComponent as IconSearch } from 'bootstrap-icons/icons/search.svg';

// const Search = () => {
//   return (
//     <form action="#" className="search">
//       <div className="input-group">
//         <input id="search" name="search" type="text" className="form-control" placeholder="Search" required />
//         <label className="visually-hidden" htmlFor="search"></label>
//         <button className="btn btn-primary text-white" type="submit" aria-label="Search">
//           <IconSearch />
//         </button>
//       </div>
//     </form>
//   );
// };
// export default Search;
