import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../components/LoadingSpinner';
import Header from '../components/Header';
import TopMenu from '../components/TopMenu';
import Footer from '../components/Footer';
import { useAuth } from '../hooks/useAuth';
import { loadingUpdate } from '../redux/loadingSlice';
import Pusher from 'pusher-js';
import Axios from '../lib/axios';
import { handleErrors } from '../lib/handleErrors';
import { setUser } from '../redux/userSlice';

const DefaultLayout = ({ children }) => {
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const { user, isLoading } = useAuth({
    middleware: 'guest'
  });

  const [count, setCount] = useState();

  useEffect(() => {
    dispatch(loadingUpdate(isLoading));
  }, [isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios().get('/api/client/cart/get/total_cart');
        setCount(res.data.data);
        if (!res.data.status) {
          dispatch(loadingUpdate(true));
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(setUser(null));
        } else {
          handleErrors(error);
        }
      }
    };
    const loadPusher = (customer_id) => {
      const pusher = new Pusher(process.env.REACT_APP_BACKEND_URL, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true
      });

      const channelCart = pusher.subscribe(`channel-cart-${customer_id}`);
      channelCart.bind('event-cart', (data) => {
        setCount(data.cart);
      });
    };
    if (user) {
      fetchData();
      loadPusher(user.id);
    }
  }, [user]);

  return (
    <React.Fragment>
      <Header totalCart={count} />
      <TopMenu />
      <LoadingSpinner isLoading={loading} />
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default DefaultLayout;
