import Axios from '../lib/axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/userSlice';
import firebase from '../firebase/config';

// import firebase from "../firebase/config";

export const useAuth = ({ middleware, redirectIfAuthenticated }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const publicRoute = [
    '/',
    '/account/email/signin',
    '/account/phone/signin',
    '/account/phone/verify-otp',
    '/account/email/verify-otp',
    '/account/email/signup',
    '/account/email/forgotpassword',
    '/account/email/resetpassword',
    '/return-policy',
    '/terms-of-use',
    '/privacy-policy',
    '/support'
  ];

  const csrf = () => Axios().get('/sanctum/csrf-cookie');

  const login = async ({ data }, success, fail) => {
    await csrf();

    Axios()
      .post('/client/login', data)
      .then((response) => {
        currentUser();
        success(response);
        navigate('/');
      })
      .catch((error) => {
        fail(error);
      });
  };

  const emailVerifyOtp = async ({ data }, success, fail) => {
    await csrf();

    Axios()
      .post('/client/email/verify_otp', data)
      .then((response) => {
        currentUser();
        success(response);
        navigate('/');
      })
      .catch((error) => {
        fail(error);
      });
  };

  const logout = async () => {
    // if (user) {
    const response = await Axios()
      .post('/client/logout')
      .then(() => {
        // firebase.auth().signOut();
        // setUser(null);
        if (firebase.auth()) {
          firebase.auth().signOut();
        }
        dispatch(setUser(null));
        window.location.pathname = '/';
      })
      .catch((e) => {
        console.log(e);
      });
    // }
  };

  const currentUser = () => {
    setIsLoading(true);
    // if (!publicRoute.includes(window.location.pathname)) {
    //   navigate('/account/email/signin');
    // }
    Axios()
      .get('/api/client/user')
      .then((response) => {
        // if (response.data.message == 'Unauthenticated' || (response.status == 401 && !publicRoute.includes(window.location.pathname))) {
        // navigate('/account/email/signin');
        // } else if (response.data != 'Unauthenticated' && Object.keys(response.data).length > 1 && response.data.name == null) {
        //   setUser(response.data);
        //   navigate('/register');
        // } else if (response.data.message != 'Unauthenticated' || response.status != 401) {
        dispatch(setUser(response.data));
        // }
      })
      .catch((e) => {
        if (e.response.status == 401 && !publicRoute.includes(window.location.pathname)) {
          navigate('/account/email/signin');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticated && user) {
      navigate(redirectIfAuthenticated);
    }

    // Force to /, if user for go to /login
    if (middleware === 'guest' && user && redirectIfAuthenticated === '/') {
      navigate('/');
    }

    // if (middleware === "auth" && !user) {
    // 	logout();
    // }
  }, [user, middleware, redirectIfAuthenticated]);

  useEffect(() => {
    if (!user) {
      currentUser();
    }
  }, []);

  return {
    user,
    isLoading: isLoading,
    isAuthenticated: !!user,
    login,
    emailVerifyOtp,
    logout
  };
};
