import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { faAndroid, faApple, faFacebookF, faInstagram, faTwitter, faWindows, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconBadgeAd } from 'bootstrap-icons/icons/badge-ad.svg';
import { ReactComponent as IconBriefcase } from 'bootstrap-icons/icons/briefcase.svg';
import { ReactComponent as IconEnvelope } from 'bootstrap-icons/icons/envelope.svg';
import { ReactComponent as IconGift } from 'bootstrap-icons/icons/gift.svg';
import Axios from '../lib/axios';
import { handleErrors } from '../lib/handleErrors';

const Footer = () => {
  const [category, setCategory] = useState();
  const [platformUrl, setPlatformUrl] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const responseCategory = await Axios().get(`/api/client/category/random`);
      setCategory(responseCategory.data.data);
      const responsePlatformUrl = await Axios().get(`/api/client/platform/url`);
      setPlatformUrl(responsePlatformUrl.data.data);
    } catch (error) {
      handleErrors(error);
    }
  };

  return (
    <React.Fragment>
      <footer>
        <div className="container-fluid bg-primary">
          <div className="row ">
            <div className="col-md-9 py-3 text-white">Get connected with us on social networks!</div>
            <div className="col-md-3 py-3 text-center text-white">
              {platformUrl &&
                platformUrl.map((value, index) => {
                  return (
                    <Fragment key={index}>
                      {value.platform_name == 'Apple' && (
                        <Link target={'_blank'} to={value.url} title="Apple">
                          <FontAwesomeIcon icon={faApple} className="text-light me-3" />
                        </Link>
                      )}
                      {value.platform_name == 'Windows' && (
                        <Link target={'_blank'} to={value.url} title="Windows">
                          <FontAwesomeIcon icon={faWindows} className="text-light me-3" />
                        </Link>
                      )}
                      {value.platform_name == 'Android' && (
                        <>
                          <Link target={'_blank'} to={value.url} title="Android">
                            <FontAwesomeIcon icon={faAndroid} className="text-light me-3" />
                          </Link>
                          |
                        </>
                      )}
                      {value.platform_name == 'Twitter' && (
                        <Link target={'_blank'} to={value.url} title="Twitter">
                          <FontAwesomeIcon icon={faTwitter} className="text-light ms-3 me-3" />
                        </Link>
                      )}
                      {value.platform_name == 'Facebook' && (
                        <Link target={'_blank'} to={value.url} title="Facebook">
                          <FontAwesomeIcon icon={faFacebookF} className="text-light me-3" />
                        </Link>
                      )}
                      {value.platform_name == 'Instagram' && (
                        <Link target={'_blank'} to={value.url} title="Instagram">
                          <FontAwesomeIcon icon={faInstagram} className="text-light me-3" />
                        </Link>
                      )}
                      {value.platform_name == 'YouTube' && (
                        <Link target={'_blank'} to={value.url} title="YouTube">
                          <FontAwesomeIcon icon={faYoutube} className="text-light me-3" />
                        </Link>
                      )}
                    </Fragment>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="container-fluid bg-dark text-white">
          <div className="row ">
            <div className="col-md-3 py-3">
              <div className="h6">Simasco Shop</div>
              <hr />
              <p>
                At Simasco Shop, we strive to provide you with quality products at competitive prices. We are committed to ensuring your
                shopping experience is seamless and enjoyable. Browse our marketplace today and find what you need to make your life better,
                more convenient, and more stylish. Simasco Shop – where variety meets value!
              </p>
            </div>
            <div className="col-md-3 py-3">
              <div className="h6">Categories</div>
              <hr />
              <ul className="list-group list-group-flush">
                {category &&
                  category.map((value, index) => {
                    return (
                      <li className="list-group-item bg-dark text-white border-light" key={index}>
                        <Link
                          to={`/category/${value.name.toLowerCase().replace(/\s/g, '-')}`}
                          className="text-decoration-none text-white stretched-link"
                        >
                          {value.name}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="col-md-3 py-3">
              <div className="h6">Policy</div>
              <hr />
              <ul className="list-group list-group-flush">
                <li className="list-group-item bg-dark text-white border-light">
                  <Link to="/return-policy" className="text-decoration-none text-white stretched-link">
                    Return Policy
                  </Link>
                </li>
                <li className="list-group-item bg-dark text-white border-light">
                  <Link to="/terms-of-use" className="text-decoration-none text-white stretched-link">
                    Terms Of Use
                  </Link>
                </li>
                <li className="list-group-item bg-dark text-white border-light">
                  <Link to="/privacy-policy" className="text-decoration-none text-white stretched-link">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 py-3">
              <div className="h6">Address</div>
              <hr />
              <address>
                <strong>Powered By: Global Perkasa Jaya. PT</strong>
                <br />
                JL. Palangkaraya, No. 145 DE
                <br />
                Ps. Baru, Kec. Medan Kota
                <br />
                Kota Medan, Sumatera Utara 20212
                <br />
                <abbr title="Phone">Phone:</abbr> (061) 4148287
              </address>
              <div className="h6">Customer Care</div>
              <hr />
              <IconEnvelope /> <a href="mailto:support@simasco.live">support@simasco.live</a>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-secondary text-white text-center">
          <div className="row">
            <div className="col-md-2 py-2">
              <Link to="/" className="text-white text-decoration-none">
                <IconBriefcase className="text-warning" /> Partner with us
              </Link>
            </div>
            <div className="col-md-2 py-2">
              <Link to="/" className="text-white text-decoration-none">
                <IconBadgeAd className="text-info" /> Advertise
              </Link>
            </div>
            <div className="col-md-2 py-2">
              <Link to="/" className="text-white text-decoration-none">
                <IconGift className="text-dark" /> Gift
              </Link>
            </div>
            <div className="col-md-3 py-2">© {new Date().getFullYear()} Shop Simasco.live</div>
            <div className="col-md-3 py-2 bg-white">
              <img src="../../images/payment/american_express.webp" width="32" alt="American Express" className="me-2" />
              <img src="../../images/payment/maestro.webp" width="32" alt="Maestro" className="me-2" />
              <img src="../../images/payment/netbanking.webp" width="32" alt="Net Banking" className="me-2" />
              <img src="../../images/payment/paypal.webp" width="32" alt="Paypal" className="me-2" />
              <img src="../../images/payment/rupay.webp" width="32" alt="Rupay" className="me-2" />
              <img src="../../images/payment/upi.webp" width="32" alt="UPI" className="me-2" />
              <img src="../../images/payment/visa.webp" width="32" alt="Visa" className="me-2" />
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
export default Footer;
