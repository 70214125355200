import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconBellFill } from 'bootstrap-icons/icons/bell-fill.svg';
import { ReactComponent as IconCart3 } from 'bootstrap-icons/icons/cart3.svg';
import { ReactComponent as IconDoorClosedFill } from 'bootstrap-icons/icons/door-closed-fill.svg';
import { ReactComponent as IconHeartFill } from 'bootstrap-icons/icons/heart-fill.svg';
import { ReactComponent as IconInfoCircleFill } from 'bootstrap-icons/icons/info-circle-fill.svg';
import { ReactComponent as IconListCheck } from 'bootstrap-icons/icons/list-check.svg';
import { ReactComponent as IconList } from 'bootstrap-icons/icons/list.svg';
import { ReactComponent as IconPersonBadgeFill } from 'bootstrap-icons/icons/person-badge-fill.svg';
import { ReactComponent as IconStarFill } from 'bootstrap-icons/icons/star-fill.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Search from './Search';

const Header = (props) => {
  const { totalCart } = props;
  const { user, isLoading, logout } = useAuth({
    middleware: 'guest'
  });

  return (
    <React.Fragment>
      <header className="p-3 border-bottom bg-light">
        <div className="container-fluid">
          <div className="row g-3 d-flex align-items-center">
            <div className="col-md-3 text-center">
              <Link to="/">
                <img alt="logo" src="../../images/logo.png" style={{ width: '70px', height: '70px' }} />
              </Link>
            </div>
            <div className="col-md-5">
              <Search />
            </div>

            <div className="col-md-4">
              {!isLoading && user && (
                <div className="position-relative d-inline me-3">
                  <Link to="/cart" className="btn btn-primary">
                    <IconCart3 className="i-va" />
                    <div className="position-absolute top-0 start-100 translate-middle badge bg-danger rounded-circle">
                      {!totalCart <= 0 && totalCart}
                    </div>
                  </Link>
                </div>
              )}
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-secondary rounded-circle border me-3"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  aria-label="Profile"
                  data-bs-toggle="dropdown"
                >
                  <FontAwesomeIcon icon={faUser} className="text-light" />
                </button>
                {!isLoading && user ? (
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/account/profile">
                        <IconPersonBadgeFill /> My Profile
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/account/list-address">
                        <IconList /> My Address
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/account/transaction">
                        <IconStarFill className="text-warning" /> My Transaction
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/account/orders">
                        <IconListCheck className="text-primary" /> Orders
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/account/wishlist">
                        <IconHeartFill className="text-danger" /> Wishlist
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/account/notification">
                        <IconBellFill className="text-primary" /> Notification
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/support">
                        <IconInfoCircleFill className="text-success" /> Support
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" onClick={logout}>
                        <IconDoorClosedFill className="text-danger" /> Logout
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/account/email/signup">
                        <IconDoorClosedFill className="text-danger" /> Sign Up
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/account/email/signin">
                        <IconPersonBadgeFill /> Sign In
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/support">
                        <IconInfoCircleFill className="text-success" /> Support
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
export default Header;
